import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/login/privateRoute"
import Profile from "../components/login/profile"
import LoginFirebase from "../components/login/loginFirebase"
const Test = () => <h1>This is my private route</h1>

// foto app App 
const App = () => {
  return(
    <Layout>
      <Router basepath="/app">
        <LoginFirebase path="/login" />
        <Profile path="/adam" />
        <Profile path="/profile" />
      </Router>
    </Layout>
  )
}

export default App
